import React, { useState, useRef } from 'react';
// import CartSummary from './components/CartSummary';
// import Products from './components/Products';

// import header from './ishinoko_header.jpg';
import flyer from './flyer.jpg'
import './App.css';

// import kurakakeyama from './kurakakeyama.jpeg';

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-60)   

function App() {
  const [language, setLanguage] = useState('jp');

  const myRef = useRef(null)
  // const executeScroll = () => scrollToRef(myRef)

  // console.log(language)
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  return (
    <div>
      <div className="languages">
        <div onClick={() => setLanguage('jp')} className="languagesbutton">JP</div>
        <div onClick={() => setLanguage('en')} className="languagesbutton">EN</div>
      </div>
      {/* <div className="tickets">
        {language==='en' &&
        <div onClick={executeScroll} className="languagesbutton">Tickets</div>}
        {language==='jp' &&
        <div onClick={executeScroll} className="languagesbutton">チケット</div>}
      </div> */}
    <main>
      <br />
      <img alt={'Flyer'} src={flyer} />
      {language==='en' &&
      <p className="infotext">
        10/31 (Sat) - 11/02 (Mon)<br />
        An intimate outdoor music festival at Takigahara Farm, Ishikawa<br />
        Limited 150 Tickets <br />
      </p>}
      {language==='jp' &&
      <p className="infotext">
        10/31（土）- 11/02（月）<br />
        滝ヶ原ファーム, 石川での心地良い屋外の音楽フェス <br />
        チケット150枚限定 <br />
      </p>}
      <br />
      {/* {language==='en' &&
      <h2>Concept</h2>}
      {language==='jp' &&
      <h2>コンセプト</h2>} */}
      {language==='en' &&
      <p className="concept">
A small community at the foot of a mountain, surrounded by ancient stone.<br /><br />

People have dug deep into the rock, and it has transformed them, just as they have transformed it. They look back at their history, and they look to the future.<br /><br />

And in the present, they take a moment to pause. They take stock of a year which has shaken the foundations of our world. Which has kept folk separated, isolated, afraid. They know that people need to be together to be fully human. To dance, smile, and feel something as one. To be reminded why life is a beautiful thing.<br /><br />

They want to invite you to their home to gather safely, to break bread together, to listen to music and dance in nature. To support each other and celebrate life, in all of its colours and tempos. You may not know them, but they are there, and they care for you. <br /><br />

Come and find them in Ishikawa.<br />
     </p>}
      {language==='jp' &&
      <p className="concept">
古代の石に包まれた山の麓に、小さな共同体がある。彼らが石を深く掘り、変化させたのと同じように、石も彼らを変化させてきた。己の歴史を振り返り、己の未来を見つめる。そして現在では、立ち止まって一休みをしている。人々を引き離し、孤独と恐怖を抱かせ、世界の基盤を揺るがした一年についてじっくり考える。<br /><br />

彼らは人間が人間であるためには、一緒にいなくてはならないと知っている。<br /><br />

一つになって踊り、微笑み、物事を感じる。<br /><br />

人生の美しさを思い出すことが必要である。<br /><br />
 
精彩や音色に溢れた人生を共に祝うため、あなたを彼らの故郷へと誘いたい。みんなと共に自然の中で集まったり、食事をしたり、支え合ったり、音楽を楽しんだりするために。<br /><br />

まだ知らないかもしれないけれど、きっとあなたのことを待っている。石川の滝ケ原に彼らを探しに来てください。

      </p>}
      <br />
      <br />
      <hr />
      <br />
      {language==='en' &&
      <h2>Lineup</h2>}
      {language==='jp' &&
      <h2>ラインアップ</h2>}
      {language==='en' &&
      <p>
        Haruka (Future Terror) - 4hr set<br />
        ¥ØU$UK€ ¥UK1MAT$U<br />
        Sapphire Slows (Nous / AD 93 / Hivern Discs) - 4hr set<br />
        Chee Shimizu (Organic Music) - 4hr set<br />
        Chris SSG (MNML SSGS) {"&"} David Dicembre<br />
        7e<br />
        Saskia (live)<br />
        Sakuma (Modest)<br />
        Taku Hirayama (Junktion)<br />
        PPTV<br />
        NEP {"&"} GGK　(ELCTRFYN / Mo'House)<br />
        Calpiss<br />
        Shunhor (euphony)<br />
        Yurika Hanashima (live)<br />
        The Rimini Rimmers<br />
        DJ Lawson<br />
        Toss Wang<br />
        生ハム<br />
        more TBA<br /><br />

        Soundsystem: <br />
        Funktion One<br />
      </p>}
      {language==='jp' &&
      <p>
        Haruka (Future Terror) - 4hr set<br />
        ¥ØU$UK€ ¥UK1MAT$U<br />
        Sapphire Slows (Nous / AD 93 / Hivern Discs) - 4hr set<br />
        Chee Shimizu (Organic Music) - 4hr set<br />
        Chris SSG (MNML SSGS) {"&"} David Dicembre<br />
        7e<br />
        Saskia (live)<br />
        Sakuma (Modest)<br />
        Taku Hirayama (Junktion)<br />
        PPTV<br />
        NEP {"&"} GGK　(ELCTRFYN / Mo'House)<br />
        Calpiss<br />
        Shunhor (euphony)<br />
        Yurika Hanashima (live)<br />
        The Rimini Rimmers<br />
        DJ Lawson<br />
        Toss Wang<br />
        生ハム<br />
        more TBA<br /><br />

        サウンドシステム: <br />
        Funktion One<br />
      </p>}
      <br />
      {language==='en' &&
      <h2>Accomodation</h2>}
      {language==='jp' &&
      <h2>宿泊施設</h2>}
      {language==='en' &&
      <p>
        3 day festival ticket includes free outdoor camping, there will also be free ‘indoor camping’ accommodation for the first 100 ticket buyers.<br /><br />

        Indoor accommodation will be in public venues around the village, including the village hall and former elementary school. This will be shared accommodation, please bring your own sleeping mat and sleeping bag. All accommodations have toilets and are within 10mins walk from the stage area.<br /><br />

        If camping outdoors, it will be cold at night, so please bring sufficient clothing and a good quality sleeping bag.<br /><br />

        Onsen ryokan are also available within 10-15 mins drive. If there is enough interest we may be able to run a shuttle bus. Email <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> with ‘accommodation’ in the subject line for more info.<br />
      </p>}
      {language==='jp' &&
      <p>3日間チケットにはキャンプ代も含まれます。初めの100枚のチケットをご購入するお客様は「屋内キャンプ」も含まれます。      <br /><br />
 
 「屋内キャンプ」は公民館や小学校などの、滝ケ原のあちこちにあります。宿泊は共有式です。全部の屋内キャンプはトイレの設備があり、イベントから徒歩10分圏内にあります。普段のキャンプと同じように、キャンプ用の装備をご持参ください。<br /><br />

 屋外キャンプをする方は夜間が非常に寒くなりますので、十分にご準備ください。  <br /><br />

 車で15分圏内に温泉や旅館もあります。十分なご関心を頂ければ、シャトルバスを用意致できる可能性もあります。<br /><br />
       
      詳細につきましてはメールにてお問い合わせください。「<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>」＊件名に「宿泊」とお書きください。
      </p>}
      <br />
      {language==='en' &&
      <h2>Safe Space</h2>}
      {language==='jp' &&
      <h2>私たちが培いたい空間について</h2>}
      {language==='en' &&
      <p>We are committed to creating a party where people of any gender, sexuality, ethnicity, age and abledness can feel safe and free: we reject homophobia, transphobia, racism, ageism, and ableism. At this event all people will be respected. Everyone who is willing to approach others with respect and a free, open spirit is welcome. We aim to create a space where all people can feel happy and act freely. Anyone exhibiting any form of misogynist, transphobic, homophobic, racist or other bad behavior will be warned, and in extreme circumstances may be asked to leave the event. 
          <br /><br />
          Please play kind and help us create something positive together.
      </p>}
      {language==='jp' &&
      <p>私たちは、すべての人が安心してオープンに楽しめる空間づくりを目指しています。あらゆる性別、セクシュアリティ、人種、年齢、身体的マイノリティに関係なく、すべての人に尊敬を持ち、自由でオープンなマインドで他者に寄り添える方、そして自分の性別に関係なく、トランスジェンダー、クィア、Aセクシュアルなど、あらゆるセクシュアリティの人々を支持する方の参加を歓迎いたします。嫌悪的、差別的な行為を示す方は注意をさせて頂き、極端な場合には退場して頂きます。
      <br /><br />
      ポジティブな環境を、皆様の力で作りましょう！YAY！
      </p>}
      <br />
      {language==='en' &&
      <h2>Food and Drink</h2>}
      {language==='jp' &&
      <h2>飲食</h2>}
      {language==='en' &&
      <p>
        We will have a small range of food stalls available, including vegetarian and vegan options, and <a href="https://takigaharafarm.com/en#cafe" target="_blank" rel="noopener noreferrer">Takigahara Cafe</a> will be open throughout the festival. Drinks will include beer, natural wine, local sake and Japanese whisky provided by local bar MOSS.
      </p>}
      {language==='jp' &&
      <p><a href="https://takigaharafarm.com/en#cafe" target="_blank" rel="noopener noreferrer">滝ケ原カフェ</a>といくつかの屋台はフェス中ずっと営業しております。ヴィーガン、ベジタリアンの選択肢もあります。
      MOSS BARが提供するビール、ナチュラルワイン、地元のお酒、国産ウイスキーもご用意しております。
      </p>}
      <br />
      {language==='en' &&
      <h2>Onsen Shuttle</h2>}
      {language==='jp' &&
      <h2>温泉シャトル</h2>}
      {language==='en' &&
      <p>
        There will be an onsen shuttle running at various times throughout the festival. Tickets and information will be available once you arrive.
      </p>}
      {language==='jp' &&
      <p>温泉シャトルのご用意をしております。乗車券や時刻表などはフェスに着いたらスタッフまで声をお掛けください。</p>}
      <br />
      <div ref={myRef} />
      {/* {language==='en' &&
      <h2>Tickets</h2>}
      {language==='jp' &&
      <h2>チケット</h2>}
      {language==='en' &&
      <p>
        Refunds will be accepted until 1 week before the event (10/24), we may consider refunds after that date in extreme circumstances. Email <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> to request a refund.
        <br /><br />
        Payments are handled through Stripe. Upon clicking purchase you will be directed to a secure Stripe payment page.
      </p>}
      {language==='jp' &&
      <p>
返金はイベントの1週間前(10/24)までとさせていただきますが、極端な状況下ではそれ以降の返金も検討させていただきます。「<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>」までご連絡ください。
<br /><br />
お支払いはStripeを介して処理されます。購入をクリックすると、安全なStripeの支払いページに移動します。</p>}
      <br />
      <CartSummary language={language}/>
      <Products language={language}/>
      <br />
      <br /> */}
      {/* <hr /> */}
      <br />
      {language==='en' &&
      <h2>Parking</h2>}
      {language==='jp' &&
      <h2>駐車場について</h2>}
      {language==='en' &&
      <p>
        If you wish to park at the venue, please buy a parking ticket below. There will be no parking available for those without a parking ticket.
      </p>}
      {language==='jp' &&
      <p>会場の駐車場は駐車券の購入者専用です。駐車場を使用される場合は、必ず予め駐車券を購入してください。</p>}
      <br />
      {language==='en' &&
      <h2>Access</h2>}
      {language==='jp' &&
      <h2>アクセス</h2>}
      {language==='en' &&
      <div>
        <a href="https://goo.gl/maps/HKEnAgofw72Pf6SHA" target="_blank" rel="noopener noreferrer">Google Maps</a><br />
        If you come by car you will need a parking ticket (details below).      
      <br />
      <h3>By plane</h3>
      1hr from Tokyo Haneda, ~¥25,000 round trip<br />
      <blockquote>
      Fly to Komatsu airport (1hr from Tokyo Haneda), then either:<br />
      - Shuttle bus to Komatsu Station (10mins) -{">"} train to Kagaonsen station (15mins) -{">"} shuttle bus to Takigahara (15mins, need to book, details below)<br />
      - Rent car from airport e.g. Orix, drive to Takigahara (30mins). You will need a parking ticket (details below).
      </blockquote>
      <h3>By train</h3>
      <h4>From Tokyo station:</h4>
      3hrs, ¥30,000 round trip or up to 50% cheaper via <a href="https://www.eki-net.com" target="_blank" rel="noopener noreferrer">eki-net</a><br />
      <blockquote>
        Shinkansen to Kanazawa (3hrs), then either:<br />
        - Train to Kagaonsen station (25mins express, 45mins local train) + shuttle bus to Takigahara (15mins, need to book, details below) <br />
        - Rent car from Kanazawa / Komatsu / Kagaonsen station, drive to Takigahara (1hr / 30mins / 15mins). You will need a parking ticket (details below).
      </blockquote>
      <h4>From Osaka station:</h4>
      2hrs, ¥14,000 round trip<br />
      <blockquote>
        JR Thunderbird direct to Kagaonsen station (2hrs), then either:<br />
        - Shuttle bus to Takigahara (15mins, need to book, details below)<br />
        - Rent car from Kagaonsen station, drive to Takigahara (15mins). You will need a parking ticket (details below).
      </blockquote>
      <h3>By highway bus</h3>
      ~ 8hrs from Tokyo, ~¥8,000 round trip<br />
      <blockquote>
        Bus to Komatsu / Kanazawa (~8hrs from Tokyo, e.g. via <a href="https://www.bushikaku.net" target="_blank" rel="noopener noreferrer">bushikaku.net</a>)<br />
          – Train to Kagaonsen station + shuttle bus to Takigahara (15mins, need to book, details below) <br />
          - Rent car from Kanazawa / Komatsu / Kagaonsen, drive to Takigahara (1hr / 30mins / 15mins). You will need a parking ticket (details below).
      </blockquote>
      <h3>Shuttle bus</h3>
      We will run a shuttle bus service from Kagaonsen station to the festival location on Saturday, and back to the station on Monday and Tuesday. Please send us a message to <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> with ‘shuttle bus’ in the subject line if you wish to book. Tickets can be bought for ¥1,000 when boarding the bus.
      <h3>Taxis</h3>
      From Komatsu airport / Komatsu station (30mins) ~¥8,000<br />
      From Kagaonsen station (15mins) ~¥4,000
      <br /><br />
      </div>}
      {language==='jp' &&
      <div>
        Google マップは<a href="https://goo.gl/maps/HKEnAgofw72Pf6SHA " target="_blank" rel="noopener noreferrer">こちら</a>です。 <br />
        ＊会場に駐車する場合は駐車券が必要です。詳細は下記です。
      <br />
      <h3>飛行機で</h3>
      東京羽田から1時間, ~25,000円 往復
      <blockquote>
        小松空港に着きましたら、下記のルートをご利用ください。<br />
        - 小松駅行きのシャトルバス (10分) →加賀温泉駅行きの電車 (15分) →滝ケ原行きのシャトルバス (15分)
        </blockquote>
  ＊　滝ケ原行きのシャトルバスは予約制です。詳細は下記です。<br />
  ＊　小松空港にORIXなどのレンタカーサービスもあります。会場まで30分かかります。会場に駐車する場合は駐車券が必要です。詳細は下記です。
      <h3>電車で</h3>
      <h4>東京駅から</h4>
      3時間, 〜30000円　往復、 半額キャンペーンで　〜15000円　往復（<a href="https://www.eki-net.com" target="_blank" rel="noopener noreferrer">eki-net</a>で検索）
      <blockquote>- 金沢駅行きの新幹線 (3時間) →加賀温泉行きの電車 (15分) →滝ケ原行きのシャトルバス (15分)</blockquote> 
      <h4>大阪駅から</h4>
      2時間, 〜14000円 往復
      <blockquote>- 加賀温泉行きのJ Rサンダーバード (2時間) →滝ケ原行きのシャトルバス (15分) </blockquote>
      <p>
      ＊　滝ケ原行きのシャトルバスは予約制です。詳細は下記です。<br />
      ＊　金沢・小松・加賀温泉では、車のレンタルサービスもあります。会場までにそれぞれ1時間・30分・15分かかります。会場に駐車する場合は駐車券が必要です。詳細は下記です。
      </p>
      <h3>高速バスで</h3>
      東京から〜8時間, 〜8000円 往復　（<a href="https://www.bushikaku.net" target="_blank" rel="noopener noreferrer">bushikaku.net</a>で検索）
      <blockquote>小松、または金沢行きのバス(東京から約8時間)→加賀温泉行きの電車(15分)→滝ケ原行きのシャトルバス (15分)</blockquote>
      ＊　出発地によって加賀温泉行きのバスも使えます。バス比較でルートを確認ください。<br />
      ＊　滝ケ原行きのシャトルバスは予約制です。詳細は下記です。<br />
      ＊　金沢・小松・加賀温泉では、車のレンタルサービスもあります。会場までにそれぞれ1時間・30分・15分かかります。会場に駐車する場合は駐車券が必要です。詳細は下記です。
      <h3>加賀温泉駅・滝ケ原のシャトルバス</h3>
      予約制のシャトルバス(片道¥1000)を下記ルートでご用意しております。<br /><br />
 
      行きの便　土曜日：加賀温泉駅→滝ケ原<br />
      帰りの便　月曜日・火曜日：滝ケ原→加賀温泉駅<br />
      バスチケットは乗車時に、現金でお支払いください。<br /><br />
        
      予約先　<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>　<br />
      ＊件名に「シャトルバス」をお書きください。
      <h3>タクシーで</h3>
      小松空港、または小松駅→滝ケ原 (30分) 約¥8,000<br />
      加賀温泉駅→滝ケ原 (15分) 約¥4,000
      <br /><br />
      </div>}
      <br />
      {language==='en' &&
      <h2>COVID-19 Policy</h2>}
      {language==='jp' &&
      <h2>COVID-19の対策</h2>}
      {language==='en' &&
      <p>Below are some simple rules to keep everyone safe. <br /><br />
      - Temperatures will be tested upon arrival, and before boarding any shuttle buses.<br />
      - Mask-wearing will be encouraged, and free masks made available.<br />
      - Alcohol spray will be freely available.<br />
      - Please keep a safe distance from one another.<br />
      - No sharing drinks.<br /><br />

      Please be sensible, and of course stay home if you are feeling unwell. 
      </p>}
      {language==='jp' &&
      <p>
      - イベント到着やシャトルバスの乗車などの時に体温を計ります。<br />
      - お客様へマスク着用するようご奨励します。<br />
      - 無料マスクとアルコール消毒液をご用意しております。<br />
      - 十分に他のお客様から離れてください。<br />
      - 飲み物などをシェアしないようご協力ください。<br />
      </p>}
      <br />
      <br />
      {/* <img alt={'Kurakakeyama'} src={kurakakeyama} /> */}
    </main>
    </div>
  );
}

export default App;
