import React, { useEffect } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

import JungleBath from './JungleBath.png';


const Success = () => {
  const { clearCart } = useShoppingCart();

  useEffect(() => clearCart(), [clearCart]);

  return (
    <main>
      <h3 className="success">
      <br /><br />
        Thank you for your purchase. <br />
        ご注文いただき、ありがとうございます！<br /><br />
        {/* {' '}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span> */}
You will receive an email confirming your order soon.<br />
すぐに確定を知らせるメールをお送り致します。<br /><br />
<img alt={'Jungle Bath'} src={JungleBath} />
<br /><br />
See you on the dancefloor :)
      </h3>

    </main>
  );
};

export default Success;
